import React, { useContext } from 'react';
import Helmet from 'react-helmet';
import {
  renderPageComponents,
  getStatusAlertComponent,
} from '../util/contentful-render-utils';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import colors from '../themes/main-colors';
import { graphql } from 'gatsby';
import LeftNavMenu from '../components/left-menu-navigation';
import BreadCrumb from '../components/pge-breadcrumb';
import Container from '@material-ui/core/Container';
import './css/contentful-pages.css';
import { Field } from '../contentful.d';
import { LanguageContext } from '../providers/LanguageProvider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    menu: {
      backgroundColor: colors.white,
      padding: theme.spacing(2.5, 7.5, 0, 0),
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    heading: {
      fontSize: theme.spacing(4.5),
      color: colors.noirBlur,
      lineHeight: theme.typography.pxToRem(40),
      padding: theme.spacing(0, 0, 2.5, 0),
      fontWeight: 'bold',
      fontFamily: 'Forma-DJR-Display',
      letterSpacing: '0.720px',
      margin: 0,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(3),
        lineHeight: theme.typography.pxToRem(28),
        padding: theme.spacing(0, 0, 1.25, 0),
      },
    },
    description: {
      fontSize: theme.spacing(3),
      color: colors.noirBlur,
      lineHeight: theme.typography.pxToRem(28),
      marginBottom: theme.spacing(2.5),
      fontWeight: 100,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(2.5),
        lineHeight: theme.typography.pxToRem(24),
        marginBottom: theme.spacing(1.25),
      },
    },
    image: {
      width: '100%',
      borderRadius: 5,
      height: 275,
      objectFit: 'cover',
    },
  }),
);

export const ArticleTemplate = ({ pageContext, data }: any) => {
  const classes = useStyles();
  const { language } = useContext(LanguageContext);
  const page = pageContext[language || 'en'];

  // ** Start temporary rollback of localization for public pages **
  const articlePageData = data.allContentfulPageArticle?.nodes[0];
  // const articlePageData =
  //   data.allContentfulPageArticle?.nodes.find(
  //     (node: any) => node.node_locale === language,
  //   ) || data.allContentfulPageArticle?.nodes[0];
  // ** End temporary rollback of localization for public pages **

  const articleImageURL = articlePageData?.articleImage?.file?.url;
  const statusAlert: any = Array.isArray(articlePageData?.statusAlert)
    ? articlePageData?.statusAlert
    : articlePageData?.statusAlert
    ? [articlePageData?.statusAlert]
    : [];

  const TEMPLATE = articlePageData?.pageTemplate as Field['Template'];

  if (page?.sectionNavArray && page?.sectionNavArray?.length > 0) {
    page.sectionNavArray[0]['menuOpen'] = true;
  }
  return (
    <>
      <Helmet bodyAttributes={{ class: 'TemplatePage' }}>
        <title>
          {articlePageData?.browserTitle || articlePageData?.articleTitle}
        </title>
        {articlePageData?.metaDescriptionSeo?.metaDescriptionSeo && (
          <meta
            name="description"
            content={articlePageData.metaDescriptionSeo.metaDescriptionSeo}
          />
        )}
        {articlePageData?.excludeFromGoogleSearch && (
          <meta name="robots" content="noindex" />
        )}
      </Helmet>

      <Container className="component-container">
        {TEMPLATE === 'Left Nav' &&
          page?.sectionNavArray &&
          page?.sectionNavArray?.length > 0 && (
            <div className={classes.menu}>
              <LeftNavMenu
                leftNavMenuItems={page.sectionNavArray}
                selectedItem={page.slug}
              />
            </div>
          )}
        <div className={classes.root}>
          <BreadCrumb path={page?.breadcrumb} />
          {getStatusAlertComponent(statusAlert)}
          {articlePageData?.articleTitle && (
            <h1 className={classes.heading}>{articlePageData?.articleTitle}</h1>
          )}
          {articlePageData?.articleSubtitle && (
            <div className={classes.description}>
              {articlePageData?.articleSubtitle}
            </div>
          )}
          {articleImageURL && (
            <img
              className={classes.image}
              src={articleImageURL}
              height="275"
              width="800"
            />
          )}
          {renderPageComponents({
            entriesData: articlePageData?.articleContentEntries || null,
            pageTemplate: articlePageData?.pageTemplate,
          })}
        </div>
      </Container>
    </>
  );
};
export default ArticleTemplate;
export const query = graphql`
  query PageArticle($slug: String!) {
    allContentfulPageArticle(filter: { slug: { eq: $slug } }) {
      nodes {
        node_locale
        contentful_id
        entryName
        shortMenuTitle
        slug
        articleTitle
        articleSubtitle
        shortDescription
        pageTemplate
        browserTitle
        excludeFromGoogleSearch
        excludeFromXmlSiteMap
        metaDescriptionSeo {
          id
          metaDescriptionSeo
        }
        statusAlert {
          ...ModuleStatusAlert
        }
        articleImage {
          file {
            contentType
            url
          }
        }
        articleContentEntries {
          ...ModuleContentBody
          ...ModuleNote
          ...ModuleStatusAlert
          ...ModuleCallOut
          ...ModuleContact
          ...ModuleAccordion
          ...ModuleContentList
          ...ModuleDocumentList
          ...GallerySlider
          ...ModulePromoGroup
          ...ModuleRelated
          ...ModuleTabSet
          ...ModuleTOC
          ...ModuleImageWrapper
          ...ModuleEmbedMediaWrapper
          ...ProgramCard
          ...StaticList
        }
      }
    }
  }
`;
