import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import OpenIcon from '../../static/icon_open.svg';
import CloseIcon from '../../static/icon_close.svg';
import colors from '../../themes/main-colors';
import SlugLink from '../slug-link';
import ListItemIcon from '@material-ui/core/ListItemIcon';

const useStyles = makeStyles(theme =>
  createStyles({
    menuItem: {
      fontSize: theme.spacing(2),
      color: colors.noirBlur,
    },
    expand: {
      '& .MuiTypography-root': {
        fontSize: theme.spacing(2),
        color: colors.sparkBlue,
        paddingLeft: theme.spacing(1),
      },
      padding: theme.spacing(0),
      '& .MuiListItem-root': {
        borderBottom: '1px solid #d7d7d7',
        padding: theme.spacing(0, 2),
        whiteSpace: 'pre-wrap',
        '& .MuiListItemText-root': {
          padding: theme.spacing(1, 0),
          margin: 0,
        },
      },
      '& .MuiCollapse-container a': {
        paddingLeft: theme.spacing(1),
      },
      '& .expandItem .MuiCollapse-container a': {
        paddingLeft: theme.spacing(2),
      },
    },
    subLevel: {
      '& a': {
        textDecoration: 'none',
        fontWeight: '400',
      },
    },
    isHierarchy: {
      '& a, & span': {
        fontWeight: '700',
      },
    },
    topLevel: {
      color: colors.noirBlur,
    },
  }),
);

interface IPanel {
  labels?: string;
  id?: string;
  slug: string;
  url?: string;
  menuOpen?: boolean;
  items?: Array<IPanel>;
  selectedItem?: string | null | undefined;
}

interface IProps {
  isParent?: boolean;
  items?: Array<IPanel>;
  labels?: string;
  id?: string;
  slug?: string;
  url?: string;
  selectedItem?: string | null | undefined;
  menuOpen?: boolean;
}

const LeftNavMenuItem = (props: IProps) => {
  const { labels, url, slug, items = [], selectedItem = null } = props;
  const classes = useStyles(props);
  const isExpandable = items && items.length > 0;

  const isSelectedPage =
    (slug || url) && (slug === selectedItem || url === selectedItem)
      ? true
      : false;

  const menuOpen = isSelectedPage || props.menuOpen;

  const [open, setOpen] = React.useState(menuOpen);
  function handleClick() {
    setOpen(!open);
  }

  const MenuItemRoot = (
    <ListItem
      className={`${classes.subLevel} ${
        !isSelectedPage && props.menuOpen ? classes.isHierarchy : ''
      }`}
      selected={isSelectedPage}
    >
      <ListItemText>
        <SlugLink
          className={props.isParent ? classes.topLevel : ''}
          slug={slug || url}
          linkText={labels}
        />
      </ListItemText>
      {isExpandable && <Divider orientation="vertical" flexItem />}
      <ListItemIcon className={classes.menuItem} onClick={handleClick}>
        {isExpandable && !open && <OpenIcon />}
        {isExpandable && open && <CloseIcon />}
      </ListItemIcon>
    </ListItem>
  );

  const MenuItemChildren = isExpandable ? (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List className={`expandItem ${classes.expand}`}>
        {items.map((item, id) => (
          <LeftNavMenuItem {...item} key={id} selectedItem={selectedItem} />
        ))}
      </List>
    </Collapse>
  ) : null;

  return (
    <>
      {MenuItemRoot}
      {MenuItemChildren}
    </>
  );
};

export default LeftNavMenuItem;
