import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import HomeIcon from '@material-ui/icons/Home';
import { Link } from 'gatsby';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import colors from '../../themes/main-colors';
import SlugLink from '../slug-link';
import { useTranslation } from '../../hooks/useTranslation';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      padding: theme.spacing(1.875, 0, 0.625, 0),
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
      '& .MuiTypography-colorTextSecondary': {
        backgroundColor: 'white',
      },
      '& .MuiBreadcrumbs-ol li': {
        maxWidth: '20ch',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: colors.noirBlur,
        fontSize: theme.spacing(1.75),
        fontWeight: 500,
        lineHeight: theme.typography.pxToRem(18),
        [theme.breakpoints.down('sm')]: {
          fontSize: theme.spacing(1.5),
          lineHeight: theme.typography.pxToRem(16),
        },
        '&.MuiBreadcrumbs-separator': {
          color: colors.noirBlur,
        },
      },
      '& .MuiBreadcrumbs-ol li a': {
        textDecoration: 'none',
        color: colors.sparkBlue,
        fontWeight: 500,
      },
      '& [class*="PrivateBreadcrumbCollapsed-root"]': {
        borderRadius: '8px',
        '& [class*="PrivateBreadcrumbCollapsed-icon"]': {
          height: '16px',
          width: '32px',
          color: colors.sparkBlue,
        },
      },
      '& > nav': {
        height: 30,
        [theme.breakpoints.down('sm')]: {
          height: '100%',
        },
      },
      '& .home-icon': {
        marginTop: '3px',
      },
    },
  }),
);

type breadcrumbType = {
  to: string;
  label: string;
};

interface breadcrumbProps {
  path: breadcrumbType[];
}

export default (props: breadcrumbProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <Breadcrumbs
        maxItems={3}
        itemsBeforeCollapse={1}
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {props.path &&
          props.path
            .slice(0, -1)
            .map(({ to, label }, index) => (
              <SlugLink
                slug={to}
                linkText={
                  label === 'Home' ? (
                    <HomeIcon
                      className="home-icon"
                      fontSize="small"
                      titleAccess={t('HOME')}
                    />
                  ) : (
                    label
                  )
                }
                key={index}
              />
            ))}
      </Breadcrumbs>
    </div>
  );
};
